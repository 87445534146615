import React from "react";

import { AboutCard } from "./HamnerCards";

import Tanya from "../../static/images/tanya copy.jpeg";
import Gayle from "../../static/images/gayle copy.jpeg";
import Rex from "../../static/images/rex copy.jpeg";

export function AboutUs() {
    return (
        <>
        <div className="about-top">
            <h1>About the Hamners</h1>
            <p>
            Hamner Livestock got started in 1988 with a 'Platts' Columbia ewe lamb and a 'Platts' Columbia ram lamb. From that time
            on, the Hamner's added other breeders ewes and rams but continually strived to keep the traditional 'Platts" Columbia look. They have
            purchased replacement stock from Woolstenhulme, Dalbow, A&K, Broken Dollar, Rice, Pitts, Frey, along with many others who have passed the Hamner's expectations.
            <br></br><br></br>
            Over the years, the Hamner children, Alan and Tanya, have shown the sheep at the Uinta County Fair and Wyoming State Fair and had much success. Additionally, the family has exhibited sheep at the National
            Columbia Show and Sale, National Western Stock Show, Utah State Fair, and Eastern Idaho State Fair.
            <br></br><br></br>
            In 2015, the Hamners purchased a Natural Colored ram and added a new dimension to their wool production. They now
            sell white wool along with natural colored fleeces to handspinners. They have exhibited animals and fleeces at the National Western Stock Show and Estes Wool Festival and had great success.
            They have shipped fleeces to over 30 states including Canada.
            <br></br><br></br>
            The original flock was raised in Lyman Wyoming and in 2009 the Hamners moved the flock to Pinedale Wyoming, where it currently still resides.{" "}
          </p>
        </div>
        <div className="about-cards-wrapper">
            <AboutCard imgSrc={Tanya} 
            imgAlt="tanya hamner"
            title="Tanya Hamner"
            description="Tanya Hamner is a PRCA Photographer and a photographer professor at Western Wyoming. 
            She runs and operates the ranch from August to May, while traveling back and forth from the college 
            to the ranch. In May she hits the road to rodeos and lives 8 seconds through the camera. Tanya is the
            one who organizes the sales for the meat and wool with individuals and customers. She also keeps our 
            website up to date."
            />
            <AboutCard imgSrc={Gayle} 
            imgAlt="gayle hamner"
            title="Gayle Hamner"
            description="Gayle Hamner has been teaching as a paraeducator for 20 years and taught preschool 
            up in Tok Alaska. She now helps Tanya with the ranch and is the Sublette County Special Olympics Coordinator and 
            Coach. 
            She has a gift when it comes to special needs people. She hits the road in the summer and travels with 
            Tanya to most of the rodeos."  
            />
            <AboutCard imgSrc={Rex} 
            imgAlt="rex hamner"
            title="Rex Hamner"
            description="Rex Hamner has been teaching Agriculture Education for 37 years. He has been an Wyoming FFA Advisor
             for 33 years. He now teaches in Tok, Alaska as the vocational teacher. He coaches wrestling up there and helps
              out with dog sledding. He comes down in May to the ranch and operates it till August while Tanya is on the 
              rodeo trail."
            />
           
        </div>

        </>
    );
}
export default AboutUs;