import React, {useState}  from "react";
import HeroSection from "./HeroSection";
import HomeCards from "./HomeCards";
import { v4 as uuid } from 'uuid';


// import { AiFillStar, AiOutlineStar } from "react-icons/ai";
// import "../../../styles/utilities/HomeCards.scss";

import Form from "../../../utilities/Form/Form"
import ReviewList from "../../../utilities/Form/reviewList";



function Home() {
    const [reviews, setReviews] = useState([]);
    const [form, setForm] = useState({product: "", review: "", customer: "", id: uuid() });
    const [editing, setEditing] = useState(false);
    // const [number, setNumber] = useState(0);
    
    return (
        <>
            <HeroSection />
            <HomeCards />
            <div className="ProductReviews">
                <h1>Product Reviews</h1>
        
                <Form 
                editing={editing}
                form={form} 
                reviews={reviews} 
                setEditing={setEditing}
                setForm={setForm} 
                setReviews={setReviews}
                />
                
                <ReviewList 
                reviews={reviews} 
                setForm={setForm}
                setEditing={setEditing} 
                setReviews={setReviews} />
               
            </div>
            {/* <div className="review-wrapper">
                <div className="review">
                    <h1>What customers think of our Products</h1>
                    {Array(5)
                        .fill()
                        .map((_, index) => 
                            number >= index + 1 ? (
                                <AiFillStar style={{ color: "orange" }} 
                                onClick={() => setNumber(index + 1)}
                                />
                        
                        ) : (
                            <AiOutlineStar style={{color: 'orange'}}
                            onClick={() => setNumber(index + 1)}
                            />
                        )
                        
                    
                    )}
                    
                </div>

                <textarea placeholder="comment here.."></textarea>
                <button>Submit</button>
            </div>  */}
            {/* <Form 
                form={form} 
                reviews={reviews} 
                setForm={setForm} 
                setReviews={setReviews}/>
                <ReviewList  reviews={reviews} /> */}
         
        </>
    );
}
export default Home;