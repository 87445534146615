import React from "react";
import { v4 as uuid } from 'uuid';



export const Form = ({
    editing, 
    form, 
    reviews, 
    setEditing, 
    setForm, 
    setReviews
}) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({...form, [name]: value });
    };
 
    const handleSubmit = (e) => {
        e.preventDefault()
        setReviews([...reviews, form])
        setForm({product: "", review: "", customer: "", id: uuid()})
    };

    const handleUpdate = (e) => {
        e.preventDefault();
        setEditing(false);
        const updatedReviews = reviews.map(review => review.id === form.id ? form : review)
        setReviews(updatedReviews);
        setForm({product: "", review: "", customer: "", id: uuid()});
    };

    return (
        <form className="form" onSubmit={editing ? handleUpdate : handleSubmit}>
            <h2>What customers think of our Products</h2>
            <label htmlFor="customer">Customer Name</label>

            <input
                type="text"
                placeholder="Customer Name"
                id="customer"
                name="customer"
                value={form.customer}
                onChange={handleChange}
            />
            <label htmlFor="product">Product</label>
            <input
                type="text"
                placeholder="Product"
                id="product"
                name="product"
                value={form.product}
                onChange={handleChange}
            />
            <label htmlFor="review">Review</label>
            <textarea
                type="text"
                placeholder="What do you think of our product"
                id="review"
                name="review"
                value={form.review}
                onChange={handleChange}
            />
            <button type="submit">{editing ? "Update" : "Submit"}</button>
        </form>
    );
};


export default Form;












// import React from 'react';
// import { uuid } from 'uuidv4';
// export const Form = ({form, reviews, setForm, setReviews, handleSubmit}) => {
 
//     const handleChange = (e) => {
//         const { name, value } = e.target
//         setForm({...form, [name] : value });
//     };
//     const handleSubmit = (e) => {
//         e.preventDefault();
//         setReviews([...reviews, form])
//         setForm({product: "", review: "", id:uuid()});
//     };
    
//     return (
//         <form onSubmit = { handleSubmit }>
            
//             <h2>Leave a Review of our Products</h2>
//             <label htmlFor="customer">Name</label>
//             <input
//             value={form.customer}
//             placeholder="" 
//             id="customer" 
//             name="customer"
//             onChange = {handleChange}
//             />
//             <label htmlFor="productreview">Product</label>
//             <input 
//             type="text" 
//             placeholder="Product Name" 
//             id="product" 
//             name="product"
//             value={form.restaurant}
//             onChange = {handleChange}
//             />
//             <label htmlFor="review">Review</label>
//             <textarea
//             value={form.review}
//             placeholder="How do you like our product?" 
//             id="review" 
//             name="review"
//             onChange = {handleChange}
//             />
//         <button type="submit">Submit</button>
//         </form>
//     )
// }

// export default Form