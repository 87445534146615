// import { Link } from "react-router-dom"
import Logo from "../static/images/logo2.png";
import { Link, useMatch, useResolvedPath} from "react-router-dom"

export default function Navbar() {

  return (
    <nav className="nav">
        
      <Link to="/" className="site-title">Hamner Livestock
      <img src={Logo} alt="logo" className="logo"/></Link>
      <ul>
        
        <CustomLink to="/aboutus">About Us</CustomLink>
        <CustomLink to="/winner">Winners Circle</CustomLink>
        <CustomLink to="/meatforsale">Meat for Sale</CustomLink>
        <CustomLink to="/woolpellets">Wool Pellets</CustomLink>
      </ul>
    </nav>
  )
}

function CustomLink ({ to, children, ...props}) {
  const resolvedPath = useResolvedPath(to)
  const isActive = useMatch({ path: resolvedPath.pathname, end: true})
  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}
