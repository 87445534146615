import React from "react";

export const AboutCard = ( {
  imgSrc,
  imgAlt,
  title,
  description,

}) => {
    return (
      <div className="about-card-container">
        {imgSrc && imgAlt && (
        <img src={imgSrc} alt={imgAlt} className="about-card-img"/>
      )}
        {title && <h1 className="about-card-title">{title}</h1>}
        {description && <p className="about-card-description">{description}</p>}

      </div>
    );
}
  

