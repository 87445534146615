import { Route, Routes } from "react-router-dom";

import "./styles/App.scss";

import Navbar from "./components/navigation/Navbar";
import Home from "./components/pages/Home/Home";

import AboutUs from "./components/pages/About/AboutUs";

import MeatForSale from "./components/pages/MeatForSale/MeatForSale";
import WholesaleMeat from "./components/pages/MeatForSale/WholesaleMeat";


import WinnerCircle from "./components/pages/WinnersCircle/Winner";
import WoolPellets from "./components/pages/WoolPellets";


function App () {
  return (
    <>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/meatforsale" element={<MeatForSale />} />
          <Route path="/wholesalemeat" element={<WholesaleMeat />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/winner" element={<WinnerCircle />} />
          <Route path="/woolpellets" element={<WoolPellets />} />
        </Routes>
      </div>
    </>

   
    

  );
  
}
export default App;